import Auth from "@aws-amplify/auth"
import { Link } from "gatsby"
import React, { ReactElement, useState } from "react"
import Button from "../components/Button/Button"
import Input from "../components/Input/Input"
import { toast } from "react-toastify"
import DefaultLayout from "../templates/DefaultLayout"

import { useEffect } from "react"
import UserConfirm from "../components/UserConfirm"
import Seo from "../components/seo"

interface Props {}

const redirect_uri = `https://ap-southeast-2-beta-app.lawlite.io/common?redirectUrl=/#`

const redirectToApp = (idToken: string, accessToken: string) => {
  window.location.replace(
    `${redirect_uri}id_token=${idToken}&access_token=${accessToken}`
  )
}
export default function SignIn({}: Props): ReactElement {
  const [loading, setLoading] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [isConfirmationPage, setShowConfirmationPage] = useState(false)
  const [isMFAPage, setMFAPage] = useState(false)
  const [passcode, setPasscode] = useState("")
  const [user, setUser] = useState<any>(null)
  useEffect(() => {
    Auth.currentSession()
      .then(res => {
        res.getAccessToken().getJwtToken()
        redirectToApp(
          res.getIdToken().getJwtToken(),
          res.getAccessToken().getJwtToken()
        )
      })
      .catch(() => {
        setShowLogin(true)
      })
  }, [])

  const signIn = async () => {
    setLoading(true)
    const toastId = toast("Loading....", {
      progressClassName: "bg-brand-primary  ",
      progress: "0",
    })
    try {
      const res = await Auth.signIn(username.replace(/\s+/g, ""), password)
      toast.dismiss(toastId)
      setUser(res)
      if (
        res.challengeName === "SMS_MFA" ||
        res.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        setMFAPage(true)
        return
      }
      redirectToApp(
        res.signInUserSession.idToken.jwtToken,
        res.signInUserSession.accessToken.jwtToken
      )
    } catch (e) {
      toast.dismiss(toastId)
      if (e.name === "UserNotConfirmedException") {
        setShowConfirmationPage(true)
        return
      }
      toast(e.message, {
        type: "error",
      })
    } finally {
      setLoading(false)
    }
  }

  const signInMFA = async () => {
    setLoading(true)
    const toastId = toast("Loading....", {
      progressClassName: "bg-brand-primary  ",
      progress: "0",
    })
    try {
      const loggedUser = await Auth.confirmSignIn(
        user, // Return object from Auth.signIn()
        passcode, // Confirmation code
        "SOFTWARE_TOKEN_MFA" // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      )
      toast.dismiss(toastId)

      redirectToApp(
        loggedUser.signInUserSession.idToken.jwtToken,
        loggedUser.signInUserSession.accessToken.jwtToken
      )
    } catch (e) {
      toast.dismiss(toastId)
      toast(e.message, {
        type: "error",
      })
    } finally {
      setLoading(false)
    }
  }
  if (!showLogin) {
    return (
      <DefaultLayout>
        <div className="min-h-[80vh] flex items-center justify-center">
          Loading...
        </div>
      </DefaultLayout>
    )
  }

  if (isMFAPage && user) {
    return (
      <DefaultLayout>
        <div className="min-h-[80vh] flex  justify-center items-start">
          <div className="max-w-[500px] w-full lg:p-10 p-5 rounded-md border my-4 bg-br-primary-blue shadow-lg bg-opacity-5 mx-4">
            <h2 className="font-bold text-xl lg:text-2xl text-br-primary-blue">
              Please enter the code from your passcode app.
            </h2>
            <Input
              placeholder="Passcode"
              className="mt-10"
              value={passcode}
              onChange={({ target: { value } }) => setPasscode(value)}
            />
            <Button
              className="mt-6 text-lg !px-10 w-full"
              onClick={() => {
                if (!loading) {
                  signInMFA()
                }
              }}
            >
              {loading ? "Loading..." : "Sign In"}
            </Button>
          </div>
        </div>
      </DefaultLayout>
    )
  }

  if (isConfirmationPage) {
    return (
      <UserConfirm
        userName={username}
        onComplete={() => setShowConfirmationPage(false)}
      />
    )
  }
  return (
    <DefaultLayout>
      <Seo title="Sign In" description="" />
      <div className="min-h-[80vh] flex  justify-center items-start">
        <div className="max-w-[500px] w-full lg:p-10 p-5 rounded-md border my-4 bg-br-primary-blue shadow-lg bg-opacity-5 mx-4">
          <h2 className="font-bold text-xl lg:text-3xl text-br-primary-blue">
            Sign in
          </h2>
          <Input
            placeholder="Email"
            className="mt-10"
            value={username}
            onChange={({ target: { value } }) => setUsername(value)}
          />
          <Input
            placeholder="Password"
            type="password"
            className="mt-6"
            value={password}
            onChange={({ target: { value } }) => setPassword(value)}
          />
          <div className="mt-2">
            <Link
              to="/forgot-password/"
              className="underline text-br-primary-blue font-medium"
            >
              Forgot your password?
            </Link>
          </div>
          <Button
            className="mt-6 text-lg !px-10 w-full"
            onClick={() => {
              if (!loading) {
                signIn()
              }
            }}
          >
            {loading ? "Loading..." : "Sign In"}
          </Button>
          <div className="text-center mt-3">
            Need an account?{" "}
            <Link
              to="/sign-up/"
              className="underline text-br-primary-blue font-medium"
            >
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
